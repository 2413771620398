<template>
	<Common
		type="projects"
		@cardClick="redirect"
		@getData="get"
		:list="infoProject"
		:isLoading="isLoading"
		:meta="meta"
	/>
</template>

<script>
import Common from '@/modules/list/Common.vue'
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'

import { setCommonMetaData } from '@/utils/setMetaData'
import { i18n } from '@/i18n'

export default {
	name: 'Projects',
	components: { Common },
	setup() {
		setCommonMetaData(`${i18n.global.t('titlePages.projects')} - OWRealty`, {
			description: i18n.global.t('meta.projectsDesc'),
			type: 'article',
			title: i18n.global.t('meta.projectsTitle'),
			url: window.location.href
		})
	},
	data() {
		return {
			isLoading: false,
			infoProject: [],
			meta: {},
			processingRequest: false
		}
	},
	methods: {
		get(fresh = false) {
			if (this.processingRequest) return

			this.processingRequest = true

			createRequest(requestConfigs.GETProjectList, {
				queryPayload: {
					per_page: 24,
					page: fresh ? 1 : (this.meta.current_page || 0) + 1,
          order_by: this.$route.query.order_by,
          direction: this.$route.query.direction
				}
			})
				.then((result) => {
          if (fresh) {
            this.infoProject = result.response.items
          } else {
            this.infoProject.push(...result.response.items)
          }
					this.meta = result.response.meta
				})
				.finally(() => {
					this.isLoading = false
					this.processingRequest = false
				})
		},
		redirect(event, payload) {
			if (event.target.type !== 'button')
				this.$router.push({
					name: 'SoloProject',
					params: { slug: payload, lang: this.$store.state.uiLanguage }
				})
		}
	},
	beforeMount() {
		this.isLoading = true
		this.get()
	}
}
</script>
